:root {
  --white: #fff;
  --black: #2e2b29;
  --black-contrast: #110f0e;
  --gray-1: rgba(61, 37, 20, 0.05);
  --gray-2: rgba(61, 37, 20, 0.08);
  --gray-3: rgba(61, 37, 20, 0.12);
  --gray-4: rgba(53, 38, 28, 0.3);
  --gray-5: rgba(28, 25, 23, 0.6);
  --purple: #6a00f5;
  --purple-contrast: #5800cc;
  --purple-light: rgba(88, 5, 255, 0.05);
  --yellow-contrast: #facc15;
  --yellow: rgba(250, 204, 21, 0.4);
  --yellow-light: #fffae5;
  --red: #ff5c33;
  --red-light: #ffebe5;
  --shadow: 0px 12px 33px 0px rgba(0, 0, 0, 0.06),
    0px 3.618px 9.949px 0px rgba(0, 0, 0, 0.04);

  --editor-font-size-desktop: 22px;
  --editor-font-size-tablet: 15px;
  --editor-font-size-mobile: 14px;
}

/* Basic editor styles */
.tiptap,
.text-editor--content {
  font-size: var(--editor-font-size-desktop);
  line-height: 1.25;
  font-family: "Pretendard";
  white-space: break-spaces;

  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    list-style: revert;
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 16px;
    margin-bottom: 8px;
    text-wrap: pretty;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: "JetBrainsMono", monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid #91004e;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }

  a {
    color: var(--purple);
    text-decoration: revert;

    &:hover {
      color: var(--purple-contrast);
    }
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 1px solid var(--gray-3);
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: var(--gray-1);
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: var(--gray-2);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: var(--purple);
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}

.text-editor--content {
  font-size: var(--editor-font-size-mobile);

  @media (min-width: 480px) {
    font-size: var(--editor-font-size-tablet);
  }

  @media (min-width: 1200px) {
    font-size: var(--editor-font-size-desktop);
  }

  [style*="font-size"] {
    font-size: var(--editor-font-size-mobile);

    @media (min-width: 480px) {
      font-size: var(--editor-font-size-tablet);
    }

    @media (min-width: 1200px) {
      font-size: var(--editor-font-size-desktop);
    }
  }
}
